import * as user from './user/user.reducer';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import * as reportHistory from '../report-history/report-history-store/list';
import * as reportDetails from '../report-history/report-history-store/detail';
import * as newReportCategories from '../new-report/new-report-store/list';
import * as newReportWizard from '../new-report/new-report-store/wizard';
import { UserEffects } from './user/user.effects';

export interface State {
    auth: user.UserStateModel;
    reportDetail: reportDetails.HistoryDetailStateModel;
    reportHistory: reportHistory.HistoryStateModel;
}

export const reducers: ActionReducerMap<State> = {
    auth: user.reducer,
    reportDetail: reportDetails.reportDetailReducer,
    reportHistory: reportHistory.reducer
};

export const effects = [
    UserEffects,
    reportDetails.ReportDetailEffects,
    reportHistory.HistoryEffects
];
