import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient';
import { IUser, User, getUser } from 'app/store/user';
import { State } from "@app/store";
import { catchError, of } from 'rxjs';

export interface TagDescription {
  tagVal: string;
  tagDescription: string;
  effectiveDate: string;
  tagDisplayTxt: string;
  systemRequired: boolean;
  piiInd: boolean;
}

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view.component.html',
  styleUrls: ['./tags-view.component.scss']
})

export class TagsViewComponent implements OnInit {
    @Input() questionMappingId: string = '';

    kendoPanelBarExpandMode: string  = 'single';
    tagsView: any[] = [];
    includeSystemRequired: boolean;

    constructor (
      private store: Store<State>,
      public dynamicQuestionsService: DynamicQuestionsService,
      public logger: LoggerService, 
    ) {}

    // Only IT Admins should be able to see System tags.
    public setPrivileges(user: IUser) {
      if (user) {
          this.includeSystemRequired = User.hasPrivilege(user, 'WEB_SUADMIN');
      }
    }

    private getMappedTags(): void {
      // Grabbing the tags and/or logging errors.
      this.dynamicQuestionsService.getMappedTags(this.questionMappingId, this.includeSystemRequired).pipe(
        catchError(error => {
          this.logger.error('Error fetching mapped tags.', error);
          return of([]); // Return an empty array if an error occurs.
        })
      ).subscribe(
        (data) => {
          if (data.length === 0) {
            // If no tags are returned, push an empty row.
            this.tagsView = [{
              title: 'No tags available.',
              disabled: true
            }];
          } else {
            this.tagsView = data.map(tag => ({
              title: tag.tagVal,
              children: [
                { 
                  title: tag.tagDescription || 'No description available.',
                  disabled: true
                }
              ]
            }));
          }
        }
      );
    }

    ngOnInit(): void {
      this.store.select(getUser).subscribe(user => {
        this.setPrivileges(user);
        this.getMappedTags();
      })
    }
}
