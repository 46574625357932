// Library Dependencies
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AgGridModule } from 'ag-grid-angular';

// State Objects
import { reducers, effects } from './store';

// Shared Modules
import { ReportStructureModule } from '@shared/reportStructure/reportStructure.module';
import { AdminModule } from './admin/admin.module';
import { ErrorHandlerModule } from '@shared/error-handler-notify/error-handler.module';
import { ReportHistoryModule } from './report-history/report-history.module';
import { CoreModule } from './core.module';
import { CommonUXModule } from './shared/common-ux/common-ux.module';
import { ReportServicesModule } from '@shared/report-services/report-services.module';

// Services
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { SSEHubNewReportService } from '@shared/SSEHubClient/new-report.service';
import { SSEHubPassengersService } from '@shared/SSEHubClient/passengers.service';
import { LoggerService, NotificationService } from '@shared/error-handler-notify/services';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HelpComponent } from './help/help.component';
import { ReportHistoryComponent } from './report-history/report-history.component';
import { ToDoComponent } from './to-do/to-do.component';
import { NewReportComponent } from './new-report/new-report.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationVerificationComponent } from './notification-verification/notification-verification.component';
import { ViewAttachmentComponent } from './view-attachment/view-attachment.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PingAuthenticationModule } from '@techops-ui/ping-authentication';

// Guards
import { AppAuthGuard } from './app.guard';
import { CanDeactivateGuard } from 'app/can-deactivate.guard';
import { AdminRouteGuard } from './admin/admin.route-guard';

import { environment } from '../environments/environment';
import { NewReportModule } from './new-report/new-report.module';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToDoModule } from './to-do/to-do.module';
import { OfflineSyncComponent } from './offline-sync/offline-sync.component';
import { AppRoutingModule } from './app-routing';
import { NewReportRerouteComponent } from './new-report/new-report-reroute/new-report-reroute.component';
import { NewReportRerouteAuthGuard } from './new-report/new-report-reroute/new-report-reroute.guard';
import { ReportHistoryRerouteAuthGuard } from './report-history/report-history-reroute/report-history-reroute.guard';

// Pipes
import { CategoryInactivePipe, CategoryListHasAdditionalInfoPipe } from './new-report/new-report.pipe';
import { PhoneNotificationPipe } from './notifications/phone.pipe';
import { ReportHistoryRerouteComponent } from './report-history/report-history-reroute/report-history-reroute.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HelpComponent,
        ReportHistoryComponent,
        ToDoComponent,
        NewReportComponent,
        OfflineSyncComponent,
        NotificationsComponent,
        NotificationVerificationComponent,
        ViewAttachmentComponent,
        PhoneNotificationPipe,
        MaintenanceComponent,
        NewReportRerouteComponent,
        CategoryInactivePipe,
        CategoryListHasAdditionalInfoPipe,
        ReportHistoryRerouteComponent,
    ],
    exports: [
        NewReportRerouteComponent,
        ReportHistoryRerouteComponent
    ],
    imports: [
        CoreModule.forRoot(),
        PingAuthenticationModule.forRoot({
            authority: environment.authority,
            client_id: environment.clientId,
            idle_threshold: environment.idle_threshold,
            timeout_duration: environment.timeout_duration,
            use_html5_routing: environment.use_html5_routing,
            load_user_info: environment.load_user_info,
            log_level: environment.logLevel,
            scope: environment.scope,
            post_logout_redirect_uri: environment.logoutURL,
        }),
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: false,
                strictActionTypeUniqueness: false,
            },
        }),
        EffectsModule.forRoot(effects),
        environment.production ? [] : StoreDevtoolsModule.instrument({connectInZone: true}),
        DataTablesModule,
        CommonUXModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        ReportStructureModule,
        ReportServicesModule,
        AdminModule,
        ErrorHandlerModule,
        ReportHistoryModule,
        NewReportModule,
        ToDoModule,
        MatSelectModule,
        MatListModule,
        MatExpansionModule,
        ReactiveFormsModule,
        AgGridModule,
        HttpClientModule,
        AppRoutingModule
    ],
    providers: [
        AdminRouteGuard,
        AppAuthGuard,
        CanDeactivateGuard,
        NewReportRerouteAuthGuard,
        ReportHistoryRerouteAuthGuard,
        DynamicQuestionsService,
        SSEHubNewReportService,
        LoggerService,
        NotificationService,
        SSEHubPassengersService,
        UtilitiesService,
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
}
