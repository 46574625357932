import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import * as TagActions from './tag.actions';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient';
import { ITagDescription } from './tag.model';
import { TagService } from 'app/shared/SSEHubClient/tag.service';

@Injectable()
export class TagEffects {

    loadTagsForQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(
            TagActions.LOAD_TAGS_FOR_QUESTION_MAPPING, 
            TagActions.ADD_TAG_TO_QUESTION_MAPPING_SUCCESS, 
            TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING_SUCCESS
        ),
        switchMap((action: { payload: { questionMappingId: string } }) => {
            return this.tagService.getMappedTagsWithoutFilter(action.payload.questionMappingId).pipe(
                map(tags => new TagActions.LoadTagsForQuestionMappingSuccess({ tags })),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error loading tags for question');
                    return of(new TagActions.LoadTagsForQuestionMappingFailure(error));
                })
            );
        })
    ));

    addTagToQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.ADD_TAG_TO_QUESTION_MAPPING),
        switchMap((action: {payload :{ questionMappingId: string,  tagVal: string[] }}) => {
            return this.dynamicQuestionService.addTagMapping(action.payload.questionMappingId, action.payload.tagVal).pipe(
                tap(() => {
                    this.notificationService.showSuccess('You successfully applied a new tag(s) to the question.')
                }),
                map(() => new TagActions.AddTagToQuestionMappingSuccess({questionMappingId: action.payload.questionMappingId, tagVal: action.payload.tagVal })),
                catchError(error => {
                    console.log(error);
                    if (error.message === 'Tag already exists.') {
                        this.notificationService.showError('The tag name already exists. Go back and search for the tag.');
                    } else {
                        this.notificationService.showError('Error applying tag to question.');
                    }
                    return of(new TagActions.AddTagToQuestionMappingFailure(error));
                })
            );
        })
    ));

    removeTagFromQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING),
        switchMap((action: {  payload: { questionMappingId: string, tagVal: string } }) => {
            return this.dynamicQuestionService.removeTagMapping(action.payload.questionMappingId, action.payload.tagVal).pipe(
                map(() => {
                    this.notificationService.showSuccess('You have succesfully removed a tag(s) from the question.');
                    return new TagActions.RemoveTagFromQuestionMappingSuccess({ questionMappingId:action.payload.questionMappingId});
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error removing tag from question.');
                    return of(new TagActions.RemoveTagFromQuestionMappingFailure(error));
                })
            );
        })
    ));

    loadAllTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.LOAD_ALL_TAGS),
        switchMap(() => {
            return this.tagService.allTagsWithoutFilter().pipe(
                map(tags => new TagActions.LoadAllTagsSuccess({ tags: tags as ITagDescription[] })),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error loading all tags');
                    return of(new TagActions.LoadAllTagsFailure(error));
                })
            );
        })
    ));

    createTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.CREATE_TAG),
        switchMap((action: { payload: { tag: ITagDescription } }) => {
            return this.tagService.createTag(action.payload.tag).pipe(
                tap(() => this.notificationService.showSuccess('You have succesfully created a new tag')),
                map(tag => new TagActions.CreateTagSuccess({ tag: tag as ITagDescription })),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error creating tag');
                    return of(new TagActions.CreateTagFailure(error));
                })
            );
        })
    ));

    updateTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.UPDATE_TAG),
        switchMap((action: { payload: { tag: ITagDescription } }) => {
            return this.tagService.updateTag(action.payload.tag).pipe(
                tap(() => this.notificationService.showSuccess('Your change(s) have been saved.')),
                map(tag => new TagActions.UpdateTagSuccess({ tag: tag as ITagDescription })),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error updating tag');
                    return of(new TagActions.UpdateTagFailure(error));
                })
            );
        })
    ));

    deactivateTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.DEACTIVATE_TAG),
        switchMap((action: { payload: { tagVal: string } }) => {
            return this.tagService.deactivateTag(action.payload.tagVal).pipe(
                map(() => new TagActions.DeactivateTagSuccess({ tagVal: action.payload.tagVal })),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error deactivating tag');
                    return of(new TagActions.DeactivateTagFailure(error));
                })
            );
        })
    ));
    constructor(
        private actions$: Actions,
        private dynamicQuestionService: DynamicQuestionsService,
        private notificationService: NotificationService,
        private tagService: TagService
    ) { }
}