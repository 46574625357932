import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';


@Injectable()
export class TagService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    allTagsWithoutFilter() {
     return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/allTagsWithoutFilter');

    }

    getMappedTagsWithoutFilter(questionMappingId: string) : Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/questionMapping/' + questionMappingId + '/tagsWithoutFilter');
    }

    getTagById(tagVal:string): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/' + encodeURIComponent(tagVal));
    }


    createTag(tag) {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/tags', tag);
    }   

    updateTag(tag) {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/tags/' + encodeURIComponent(tag.id), tag);
    }

    deactivateTag(tagVal:string) {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/tags/deactivate', tagVal);
    }
}

