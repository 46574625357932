import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import {  catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getCategoryDetails, getSelectedQuestionMappingText, getAllTags , getQuestionMappingTags , getSelectedCategory } from '../category-management-store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { CategoryDetails, LoadCategoryDetail } from '../category-management-store/detail';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { ITagDescription } from '../category-management-store/tag';
export interface availableTagDescription extends ITagDescription {
  isMapped: boolean;
}
@Component({
  selector: 'app-question-tag-management',
  templateUrl: './question-tag-management.component.html',
  styleUrl: './question-tag-management.component.scss'
})

export class QuestionTagManagementComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  category$: Observable<string>;
  categoryId: number;
  profileId: number;
  questionMappingId: string;
  sort: SortDescriptor[] = [{field: "", dir: "asc"}];
  mappedTagVals: string[] = [];
  description$: Observable<string>;
  rows:number;
  isTextTruncated: boolean = false;
  availableTags$: Observable<availableTagDescription[]>;
  @ViewChild("multiselect", { static: true }) public multiselect: MultiSelectComponent;
  @ViewChild('textArea') textAreaElement: TextAreaComponent;

  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dynamicQuestionsService: DynamicQuestionsService,
    private logger : LoggerService
  ) { }

  ngOnInit() {
    this.availableTags$ = this.activatedRoute.paramMap.pipe(
      switchMap(params => {
        this.profileId = +params.get('profileId');
        this.categoryId = +params.get('categoryId');
        this.questionMappingId = params.get('questionMappingId');
     
        this.category$ = this.getCategoryDetails(this.profileId, this.categoryId);
        this.description$ = this.getQuestionMetadata(this.questionMappingId);

        return this.prepareTagCollections();
      })
    );
   

  }
  // Fetch the category details from the store's getSelectedCategory selector if not available in the store then dispatch an action to load the category details and select from getCategoryDetails selector
  getCategoryDetails(profileId: number, categoryId: number): Observable<string> {
    return this.store.select(getSelectedCategory).pipe(
      switchMap(category => {
        if (category) {
          return of(category);
        } else {
          this.store.dispatch(new LoadCategoryDetail(profileId, categoryId));
          return this.store.select(getCategoryDetails).pipe(map(category => category.description));
        }
      })
    );
  }
  ngAfterViewInit() {
    this.checkIfTextIsTruncated();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
 // Fetch the questionText and set the number of rows based on the length of the question
 getQuestionMetadata(questionMappingId: string): Observable<string> {
  return this.store.select(getSelectedQuestionMappingText).pipe(
    switchMap(questionText => {
      if (questionText) {
        console.log('Question text:', questionText);
          // questionText is available in the store
          this.rows = questionText.length > 35 ? 2 : 1;
          return of(questionText);
      } else {
          // questionText is not available in the store, make a network call
          return this.dynamicQuestionsService.getQuestionsMetaData(questionMappingId).pipe(
            map(data => {
              // Set the number of rows based on the length of the question
              this.rows = data.question.length > 35 ? 2 : 1;
              return data.question;
            }),
            catchError(error => {
              this.logger.error('Error fetching question metadata:', error);
              return of(null);
            })
          );
        }
      })
    );
  }

  prepareTagCollections(){
    // Dispatch actions to load all tags and mapped tags
    this.store.dispatch(new Actions.LoadAllTags());
    this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId }));
 

    // Select the data from the store
    return combineLatest({
      allTags: this.store.select(getAllTags),
      mappedTags: this.store.select(getQuestionMappingTags)
     
    }).pipe(
      debounceTime(0),
      map(({ allTags, mappedTags }) => {
        if(allTags.length>0 ){
          
          return this.getAvailableTagsToMap(allTags, mappedTags);
        }
      })
    );
 }

  getAvailableTagsToMap(allTags, mappedTags): availableTagDescription[] {
    const mappedTagVals = new Set(mappedTags.map(tag => tag.tagVal));
    //store the mapped tag values for showing the mapped tags in the multiselect
    this.mappedTagVals = Array.from(mappedTagVals) as string[];
    return allTags.map(tag => ({...tag, isMapped: mappedTagVals.has(tag.tagVal) }))
  }

  gotoCategories() {
    this.router.navigate(['/admin/categorymanagement', { profileId: this.profileId }]);
  }

  gotoActiveCategory() {
    this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/summary']);
  }

  checkIfTextIsTruncated() {
    const interval = setInterval(() => {
      const { scrollHeight, clientHeight } = this.textAreaElement.input.nativeElement;
      this.isTextTruncated = scrollHeight > clientHeight;
    }, 750);
    setTimeout(() => clearInterval(interval), 1500);
  }
  
  onOpen(event: any): void {
    // Prevent the MultiSelect dropdown from opening 
    event.preventDefault();
  }

  removeTag(tag: any): void {
    console.log('Removing tag:', tag);
    //TODO: Add the logic to remove the tag here
  }
}