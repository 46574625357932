import { Action } from '@ngrx/store';
import { ITagDescription } from './tag.model';  // Replace with your actual tag model

export const SELECT_QUESTION_FOR_QUESTION_MAPPING_TAG = '[Tag Management] Select Question For Question Mapping Tag Management';

// CRUD Operation Actions for Tag Management Page

export const LOAD_ALL_TAGS = '[Tag Management] Load All Tags';
export const LOAD_ALL_TAGS_SUCCESS = '[Tag Management] Load All Tags Success';
export const LOAD_ALL_TAGS_FAILURE = '[Tag Management] Load All Tags Failure';

export const CREATE_TAG = '[Tag Management] Create Tag';
export const CREATE_TAG_SUCCESS = '[Tag Management] Create Tag Success';
export const CREATE_TAG_FAILURE = '[Tag Management] Create Tag Failure';

export const UPDATE_TAG = '[Tag Management] Update Tag';
export const UPDATE_TAG_SUCCESS = '[Tag Management] Update Tag Success';
export const UPDATE_TAG_FAILURE = '[Tag Management] Update Tag Failure';

export const DEACTIVATE_TAG = '[Tag Management] Deactivate Tag';
export const DEACTIVATE_TAG_SUCCESS = '[Tag Management] Deactivate Tag Success';
export const DEACTIVATE_TAG_FAILURE = '[Tag Management] Deactivate Tag Failure';

// CRUD Operation Actions for Question Tags Page

export const LOAD_TAGS_FOR_QUESTION_MAPPING = '[Tag Management] Load Tags For Question Mapping';
export const LOAD_TAGS_FOR_QUESTION_MAPPING_SUCCESS = '[Tag Management] Load Tags For Question Mapping Success';
export const LOAD_TAGS_FOR_QUESTION_MAPPING_FAILURE = '[Tag Management] Load Tags For Question Mapping Failure';

export const ADD_TAG_TO_QUESTION_MAPPING = '[Tag Management] Add Tag To Question Mapping';
export const ADD_TAG_TO_QUESTION_MAPPING_SUCCESS = '[Tag Management] Add Tag To Question Mapping Success';
export const ADD_TAG_TO_QUESTION_MAPPING_FAILURE = '[Tag Management] Add Tag To Question Mapping Failure';

export const REMOVE_TAG_FROM_QUESTION_MAPPING = '[Tag Management] Remove Tag From Question Mapping';
export const REMOVE_TAG_FROM_QUESTION_MAPPING_SUCCESS = '[Tag Management] Remove Tag From Question Mapping Success';
export const REMOVE_TAG_FROM_QUESTION_MAPPING_FAILURE = '[Tag Management] Remove Tag From Question Mapping Failure';



// Action for selecting a question for question mapping tag management
export class SelectQuestionForQuestionMappingTag implements Action {
    readonly type = SELECT_QUESTION_FOR_QUESTION_MAPPING_TAG;
    constructor(public payload: { questionMappingId: string , questionText: string, categoryDescription: string}) { }
}

// Actions for loading all tags
export class LoadAllTags implements Action {
    readonly type = LOAD_ALL_TAGS;
}

export class LoadAllTagsSuccess implements Action {
    readonly type = LOAD_ALL_TAGS_SUCCESS;
    constructor(public payload: { tags: ITagDescription[] }) { }
}

export class LoadAllTagsFailure implements Action {
    readonly type = LOAD_ALL_TAGS_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for creating a tag
export class CreateTag implements Action {
    readonly type = CREATE_TAG;
    constructor(public payload: { tag: ITagDescription }) { }
}

export class CreateTagSuccess implements Action {
    readonly type = CREATE_TAG_SUCCESS;
    constructor(public payload: { tag: ITagDescription }) { }
}

export class CreateTagFailure implements Action {
    readonly type = CREATE_TAG_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for updating a tag
export class UpdateTag implements Action {
    readonly type = UPDATE_TAG;
    constructor(public payload: { tag: ITagDescription }) { }
}

export class UpdateTagSuccess implements Action {
    readonly type = UPDATE_TAG_SUCCESS;
    constructor(public payload: { tag: ITagDescription }) { }
}

export class UpdateTagFailure implements Action {
    readonly type = UPDATE_TAG_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for deactivating a tag
export class DeactivateTag implements Action {
    readonly type = DEACTIVATE_TAG;
    constructor(public payload: { tagVal: string }) { }
}

export class DeactivateTagSuccess implements Action {
    readonly type = DEACTIVATE_TAG_SUCCESS;
    constructor(public payload: { tagVal: string }) { }
}

export class DeactivateTagFailure implements Action {
    readonly type = DEACTIVATE_TAG_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for loading tags for a specific question mapping
export class LoadTagsForQuestionMapping implements Action {
    readonly type = LOAD_TAGS_FOR_QUESTION_MAPPING;
    constructor(public payload: { questionMappingId: string }) { }
}

export class LoadTagsForQuestionMappingSuccess implements Action {
    readonly type = LOAD_TAGS_FOR_QUESTION_MAPPING_SUCCESS;
    constructor(public payload: { tags: ITagDescription[] }) { }
}

export class LoadTagsForQuestionMappingFailure implements Action {
    readonly type = LOAD_TAGS_FOR_QUESTION_MAPPING_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for adding tags to a specific question mapping
export class AddTagToQuestionMapping implements Action {
    readonly type = ADD_TAG_TO_QUESTION_MAPPING;
    constructor(public payload: { questionMappingId: string, tagVal: string | string[] }) { }
}

export class AddTagToQuestionMappingSuccess implements Action {
    readonly type = ADD_TAG_TO_QUESTION_MAPPING_SUCCESS;
    constructor(public payload: { questionMappingId: string, tagVal: string | string[] }) { }
}

export class AddTagToQuestionMappingFailure implements Action {
    readonly type = ADD_TAG_TO_QUESTION_MAPPING_FAILURE;
    constructor(public payload: { error: string }) { }
}

// Actions for removing tags from a specific question mapping
export class RemoveTagFromQuestionMapping implements Action {
    readonly type = REMOVE_TAG_FROM_QUESTION_MAPPING;
    constructor(public payload: { questionMappingId: string, tagVal: string }) { }
}

export class RemoveTagFromQuestionMappingSuccess implements Action {
    readonly type = REMOVE_TAG_FROM_QUESTION_MAPPING_SUCCESS;
    constructor(public payload: {questionMappingId: string}) { }
}

export class RemoveTagFromQuestionMappingFailure implements Action {
    readonly type = REMOVE_TAG_FROM_QUESTION_MAPPING_FAILURE;
    constructor(public payload: { error: string }) { }
}

export type Actions
    = SelectQuestionForQuestionMappingTag
    | LoadAllTags
    | LoadAllTagsSuccess
    | LoadAllTagsFailure
    | CreateTag
    | CreateTagSuccess
    | CreateTagFailure
    | UpdateTag
    | UpdateTagSuccess
    | UpdateTagFailure
    | DeactivateTag
    | DeactivateTagSuccess
    | DeactivateTagFailure
    | LoadTagsForQuestionMapping
    | LoadTagsForQuestionMappingSuccess
    | LoadTagsForQuestionMappingFailure
    | AddTagToQuestionMapping
    | AddTagToQuestionMappingSuccess
    | AddTagToQuestionMappingFailure
    | RemoveTagFromQuestionMapping
    | RemoveTagFromQuestionMappingSuccess
    | RemoveTagFromQuestionMappingFailure