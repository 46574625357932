<ol class="breadcrumb">
    <li><a routerLink="/home"><i class="icon-home"></i> Home</a></li>
    <li><a routerLink="/admin/categorymanagement">Admin</a></li>
    <li><a routerLink="/admin/categorymanagement">Category Management</a></li>
    <li class="active">{{(category$ | async).description}}</li>
</ol>

<app-aa-spinner [loading]="(category$ | async).isLoading"></app-aa-spinner>
<p>
    <i class="icon-cers-required"></i> <i>Required</i>
</p>
<br />

<div *ngIf="(category$ | async).sections">
    <app-flight-view></app-flight-view>
    <app-question-view></app-question-view>
    <app-notes-view></app-notes-view>
    <app-miscellaneous-view></app-miscellaneous-view>
</div>

<modal #myModal>
    <modal-header>
        This appears to be a new category, how would you like to initialize it?
    </modal-header>
    <modal-content>
        <div class="questions-not-found">
            <input type="radio" name="categorySelected" [(ngModel)]="questionsNtFound" value="new">&nbsp;Empty
            Category<br>
            <input type="radio" name="categorySelected" [(ngModel)]="questionsNtFound" value="existing">&nbsp;Copy
            structure
            from existing<br>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal" (click)="gotoCategories()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="initializeCategory(questionsNtFound)">Continue</button>
    </modal-footer>
</modal>
