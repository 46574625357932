<ol class="breadcrumb">
    <li><a routerLink='/home'><i class="icon-home"></i> Home</a></li>
    <li><a routerLink="/admin/categorymanagement">Admin</a></li>
    <li><a routerLink="/admin/categorymanagement">Category Management</a></li>
    <li><a (click)="gotoActiveCategory()" class="new-link">{{ (category$ | async) }}</a></li>
    <li>Question Tags</li>
</ol>

<div class="container-fluid pe-0">
  <div class="row align-items-center">
    <div class="button-icon col-auto ">
      <a (click)="gotoActiveCategory()" class="icon-sec-chevron-left text-decoration-none"></a>
    </div>
    <div class="col">
      <div class="section-title-cers">Question tags</div>
      <div class="section-line"></div>
    </div>
  </div>
  <div class="question row pt-2">
    <div class="col-3 ps-0 align-items-center">
      <kendo-label text="Question">
        <div kendoTooltip position="bottom"  [showOn]="isTextTruncated ? 'hover' : 'none'" >
          <ng-container *ngIf="description$ | async as description">
            <kendo-textarea 
                  [title]="description" 
                  #textArea   
                  [rows]="rows" 
                  size="none" 
                  [ngClass]="this.rows === 2 ? 'question-text-multiline' : 'question-text'"
                  [readonly]="true" 
                  resizable="none" 
                  [value]="description">
            </kendo-textarea>
         </ng-container>
       </div>
      </kendo-label>
    </div>
    <div class="col-9">
      <kendo-label text="Tag(s) applied">
        <kendo-multiselect [placeholder]="'None'" id='scrollstyle' [readonly]="true" [(ngModel)]="mappedTagVals" (open)="onOpen($event)">
          <ng-template kendoMultiSelectTagTemplate let-dataItem>
            <div class="d-flex align-items-center">
              <span>{{dataItem}}</span>
              <button class="icon-sec-x-circle-outline" (click)="removeTag(dataItem)"></button>
            </div>
          </ng-template>
        </kendo-multiselect>
      </kendo-label>
    </div>
  </div>
</div>
<div class="pt-4">
  <ng-container *ngIf="availableTags$ | async as availableTags">
    <kendo-grid [kendoGridBinding]="availableTags" [selectable]="{checkboxOnly: true }" [sortable]="true" [pageSize]="6" [pageable]="true">
      <kendo-grid-checkbox-column [headerClass]="'k-checkBox-header'" [class]="'k-checkBox-cell'">
          <ng-template kendoGridCellTemplate let-dataItem>
              <input type="checkbox" KendoCheckBox [disabled]="dataItem.isMapped" [checked]="dataItem.isMapped" />
          </ng-template>        
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="tagVal" [headerClass]="'k-tagValue-header'" [class]="'k-tagValue-cell'"> 
          <ng-template kendoGridHeaderTemplate>
              <span class="k-column-title">Tag name</span> 
              <span *ngIf="sort[0].field == '' || sort[0].dir == null || sort[0].field !== column.field">
                  <i class="icon-sec-sort-default"></i>
              </span>
            </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tagDisplayTxt" [headerClass]="'k-tagDisplayText-header'" [class]="'k-tagDisplayText-cell'">
          <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
              <span class="k-column-title">Display text</span> 
              <span *ngIf="sort[0].field == '' || sort[0].dir == null || sort[0].field !== column.field">
                <i class="icon-sec-sort-default"></i  >
              </span>
            </ng-template>        
      </kendo-grid-column>
      <kendo-grid-column field="tagDescription" title="Description" [sortable]="false" [headerClass]="'k-tagDescription-header'" [class]="'k-tagDescription-cell'">
      </kendo-grid-column>
      <kendo-grid-column field="piiInd" title="PII" [sortable]="false" [headerClass]="'k-piiInd-header'" [class]="'k-piiInd-cell'">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="dataItem.piiInd == true">Yes</span>
              <span *ngIf="dataItem.piiInd == false">No</span>
          </ng-template>
      </kendo-grid-column>

      <ng-template kendoPagerTemplate let-currentPage="currentPage" let-totalPages="totalPages">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-grid-spacer></kendo-grid-spacer>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-grid-spacer></kendo-grid-spacer>
          <kendo-pager-page-sizes [pageSizes]="[10,15,20]"></kendo-pager-page-sizes>
        </ng-template>
    </kendo-grid>
  </ng-container>
</div>
