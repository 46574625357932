import { NgModule } from "@angular/core";

import { GridModule } from "@progress/kendo-angular-grid";
import { LayoutModule, PanelBarModule } from '@progress/kendo-angular-layout';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';

@NgModule({
    declarations: [
    ],
    imports: [
        IconsModule,
        ButtonsModule,
        GridModule,
        LayoutModule,
        PanelBarModule,
        InputsModule,
        LabelModule,
        TooltipsModule,
        DropDownsModule,
        DialogsModule,
        NotificationModule
        
    ],
    exports: [
        NotificationModule,
        DialogsModule,
        IconsModule,
        ButtonsModule,
        GridModule,
        LayoutModule,
        PanelBarModule,
        InputsModule,
        LabelModule,
        TooltipsModule,
        DropDownsModule
    ],
    providers: [
         { provide: ICON_SETTINGS, useValue: { type: 'font' } }
    ]
})
export class KendoUiModule { }
