<ol class="breadcrumb">
    <li><a routerLink="/home"><i class="icon-home"></i> Home</a></li>
    <li><a routerLink="/admin/categorymanagement">Admin</a></li>
    <li><a routerLink="/admin/categorymanagement">Category Management</a></li>
    <li>Tag management</li>
</ol>

<div style="float: left; padding: 10px">
    <a routerLink="/admin/categorymanagement">
        <i class="icon-circle-left icon-large"></i>
    </a> 
</div>
<h1>Tag management</h1>